.App {
    //text-align: center;
}

/**
full-calendar css
 */
.fc-timegrid-body tr {
    height: 5em !important;
}

.fc-timegrid-body tr {
    height: 5em !important;
}

.fc-scroller {
    height: 100% !important;
}

//current day column
.fc-day-today {
    //background: #fbffc1 !important;
}

//event cell
.fc-event {
    background: #b4bf00 !important;
    border: 1px solid #b4bf00 !important;
}

.fc-button-primary {
    //background: #b4bf00 !important;
    //border: 1px solid #b4bf00 !important;
}

.fc-timegrid-body tr {
    @media (min-width: 1280px){
        height: 3em !important;
    }
}
